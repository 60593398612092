<template>
    <div class='account_manage'>
        <!-- 标题 -->
        <div class="vue_title">
            <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
            <p> 回复列表</p>
        </div>
        <div class="account_main">
            <!--  -->
            <div class="main_condition">
                <!-- <div class="cond_left">
                    <el-input v-model="title" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="搜索"></el-input>
                </div> -->
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable" @selection-change="handleSelectionChange" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                    <el-table-column prop="id" label="ID" align="center" width="60"></el-table-column>
                    <el-table-column prop="flow_title" label="文章标题"></el-table-column>
                    <el-table-column prop="content" label="回复文字" width="300" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="update_time" label="回复图片" width="210">
                        <template slot-scope="scope">
                            <div class="imgList" v-if="scope.row.img">
                                <el-image class="img" style="width:55px; height: 55px" v-for="(item,index) in scope.row.img" :key="index" :src="item" :preview-src-list="srcList">
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="回复时间"></el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { EmptyData, PagingPage },
    props: {},
    data() {
        return {
            list: [],
            loading: false,
            page: 1,
            limit: 10,
            total: 0,
            id: "",
            srcList: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getList()
        }
    },
    mounted() { },
    methods: {
        // 列表
        getList() {
            var that = this
            that.loading = true
            var params = {
                id: this.id,
                page: that.page,
                limit: that.limit,
            }
            that.$flowApi.replyList(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    let arr = []
                    that.list = res.result?.data
                    that.list.forEach(ele => {
                        if (ele.img) {
                            ele.img = ele.img.split(',')
                            arr = arr.concat(ele.img)
                        }
                    })
                    this.srcList = arr
                    that.total = res.result?.total
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        back() {
            this.$router.back()
        },
    },
}
</script>

<style lang='scss' scoped>
.account_manage {
    width: 100%;
    .account_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
            .cond_right {
                .el-button {
                    padding: 8px 10px;
                }
            }
        }
    }
}
.imgList {
    display: flex;
    flex-wrap: wrap;
    .img {
        margin-right: 6px;
        margin-top: 6px;
    }
}
</style>
